<template>
    <div class="goods-container">
        <div class="script-box">
            <LiveScript />
        </div>
        <div class="coupon-container" v-if="['add', 'edit'].includes(tag)">
            <el-form style="margin-top: 20px;" :model="ruleForm" :rules="rules" label-width="100px" ref="ruleForm"
                :hide-required-asterisk="true">
                <span style="font-size:16px;">优惠券基本信息</span>
                <el-form-item style="margin-top: 24px;" label="优惠券名称" prop="name">
                    <el-input style="width:400px;" v-model="ruleForm.name" placeholder="请输入优惠券名称"></el-input>
                </el-form-item>
                <el-form-item label="促销范围" prop="range">
                    <el-radio-group v-model="ruleForm.range">
                        <el-radio label="1">指定商品可用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                    <el-button :disabled="ruleForm.id" type="primary" @click="openWindow">添加促销商品</el-button>
                    <span style="margin-left:10px">已选择{{ total }}个商品</span>
                </el-form-item>
                <div style="font-size:16px;margin-top: 40px;">优惠券内容设置</div>
                <el-form-item style="margin-top: 24px;" label="优惠券类型" prop="coupon_type">
                    <el-radio-group v-model="ruleForm.coupon_type" @change="chooseType">
                        <el-radio label="2">指定商品折扣券</el-radio>
                        <el-radio label="1">指定商品满减券</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="ruleForm.coupon_type === '1'" label="优惠券金额" prop="threshold" :rules="[
                    { required: true, message: '请输入优惠券金额', trigger: 'blur' }
                ]">
                    <el-input-number :controls="false" :precision="2" :min="1" :max="5000"
                        style="margin-left:10px;width:400px;" placeholder="请输入优惠券金额，最大不超过5000元的整数金额"
                        v-model="ruleForm.threshold"></el-input-number>
                    <span style="margin-left:10px;">元</span>
                </el-form-item>
                <el-form-item v-else label="优惠折扣" prop="threshold" :rules="[
                    { required: true, message: '请输入优惠折扣', trigger: 'blur' }
                ]">
                    <el-input-number :controls="false" :precision="1" :min="1.0" :max="9.9"
                        style="margin-left:10px;width:400px;" placeholder="请输入优惠折扣"
                        v-model="ruleForm.threshold"></el-input-number>
                    <span style="margin-left:10px;">折</span>
                </el-form-item>
                <el-form-item label="使用门槛" prop="money">
                    <span style="margin-right:10px;">满</span>
                    <el-input-number :controls="false" :precision="2"
                        :min="ruleForm.coupon_type == 2 ? 1 : ruleForm.threshold" :max="999999999.99" style="width:385px;"
                        placeholder="请输入使用门槛，门槛需高于金额" v-model="ruleForm.money"></el-input-number>
                    <span style="margin-left:10px;">元</span>
                </el-form-item>
                <el-form-item label="发行量" prop="circulation">
                    <el-input-number :controls="false" :precision="0" :min="100" :max="100000"
                        style="margin-left:10px;width:400px;" placeholder="请输入发行量，需大于等于100，最大不超过100000"
                        v-model="ruleForm.circulation"></el-input-number>
                    <span style="margin-left:10px;">张</span>
                </el-form-item>
                <el-form-item label="每人限领" prop="limit">
                    <el-select style="margin-left:10px;width:400px;" v-model="ruleForm.limit" placeholder="请选择限领数量">
                        <el-option v-for="item in couponNum" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <span style="margin-left:10px;">张</span>
                </el-form-item>
                <div class="footer">
                    <el-button @click="resetForm('ruleForm')">返回</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">创建</el-button>
                </div>
            </el-form>
        </div>
        <div v-else class="goods-table">
            <el-button style="margin-bottom:10px" type="primary" @click="saveCoupon('add')">创建优惠券</el-button>
            <el-table height="650" :data="couponList" class="customTable"
                style="width: 100%;flex: 1;border: #f0efef 1px solid;border-radius: 4px;">
                <el-table-column prop="image_url" label="商品信息" width="190px" align="left">
                    <template slot-scope="scope">
                        <div class="goodsInfo">
                            <img :src="scope.row.src.url" alt="">
                            <span class="text-overflow-2">{{ scope.row.goods_name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="优惠券名称" align="center">
                    <template slot-scope="scope"><span>{{ scope.row.name }}</span></template>
                </el-table-column>
                <el-table-column prop="coupon_type" label="优惠券类型" align="center">
                    <template slot-scope="scope"> <span>{{ scope.row.coupon_type }}</span></template>
                </el-table-column>
                <el-table-column prop="circulation" label="发放量" align="center">
                    <template slot-scope="scope"><span>{{ scope.row.circulation }}</span></template>
                </el-table-column>
                <el-table-column prop="limit" label="每人限领" align="center">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.limit == 1 ? '1张' : scope.row.limit == 2 ? '2张' : scope.row.limit == 3 ? '3张' :
                                scope.row.limit == 4 ? '4张' : scope.row.limit == 5 ? '5张' : scope.row.limit == 0 ? '不限' : ''
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link :underline="false" type="primary" @click="editShop(scope.row)">编辑</el-link>
                        <el-link style="margin-left:10px" :underline="false" type="danger"
                            @click="delItem(scope.row.id)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="添加促销商品" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%"
            :before-close="closeWinodw">
            <div class="goods-box">
                <el-table :data="goodList" style="width: 100%;flex: 1;border: #DCDCDC 1px solid;"
                    :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }"
                    :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '42px' }">
                    <el-table-column label="商品信息" prop="goods_name">
                        <template slot-scope="scope">
                            <div class="goodsInfo">
                                <img :src="scope.row.src.url" alt="">
                                <span class="text-overflow-2">{{ scope.row.goods_name }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品价格" prop="daily_price" align="center">
                        <template slot-scope="scope">
                            <span>￥{{ Number(scope.row.daily_price).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-link :underline="false" type="primary" @click="getAll">选择全部</el-link>
                        </template>
                        <template slot-scope="scope">
                            <el-link v-if="scope.row.isChecked == 1" :underline="false" type="danger"
                                @click="changeItem(scope.$index, 'cancel')">取消</el-link>
                            <el-link v-else :underline="false" type="primary"
                                @click="changeItem(scope.$index, 'choose')">选择</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center" :current-page="pages.currentPageNum" :page-size="pages.eachPageNum"
                    layout="prev, pager, next, jumper" :total="pages.total" @current-change="currentChange"></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeWinodw">取 消</el-button>
                <el-button type="primary" @click="saveGoods">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getLiveGoodsList, delCouponItem, getCouponList, saveCouponData, getCouponDetail } from "@/utils/apis";
import LiveScript from "../../../components/LiveScript.vue"
import { mapActions } from 'vuex';
export default {
    name: "GoodsManagement",
    components: {
        LiveScript
    },
    data() {
        return {
            goodList: [],
            goodsname: '',
            tempGoodsList: [],
            couponList: [],
            options: [],
            temp_id: [],
            couponNum: [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }, { label: '不限', value: 0 }],
            cate: void 0,
            dialogVisible: false,
            pages: {
                currentPageNum: 1,
                eachPageNum: 5,
                total: 0
            },
            tag: void 0,
            ruleForm: {
                id: void 0,
                name: void 0,
                range: '1',
                coupon_type: '2',
                money: void 0,
                threshold: void 0,
                circulation: void 0,
                limit: void 0,
                sale_goods_id: []
            },
            total: 0,
            rules: {
                name: [
                    { required: true, message: '请输入优惠券名称', trigger: 'blur' },
                    { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' }
                ],
                range: [{ required: true, message: '请选择促销范围', trigger: 'change' }],
                coupon_type: [{ required: true, message: '请选择优惠券类型', trigger: 'change' }],
                money: [{ required: true, message: '请输入使用门槛', trigger: 'blur' }],
                circulation: [{ required: true, message: '请输入发行量', trigger: 'blur' }],
                limit: [{ required: true, message: '请输入限领数量', trigger: 'blur' }]
            }
        }
    },
    methods: {
        ...mapActions([
            'setLiveGoods'
        ]),
        chooseType() { this.ruleForm.threshold = void 0 },
        getCouponListData() {
            let obj = {}
            getCouponList(obj).then((res) => {
                if (res.code === 200) {
                    this.couponList = res.data.list
                }
            })
        },
        getGoodsList() {
            getLiveGoodsList({
                paging: 1,
                page: this.pages.currentPageNum,
                page_size: this.pages.eachPageNum,
            }).then((res) => {
                this.goodList = res.data.list
                this.pages.total = res.data.total
                localStorage.setItem('goodsList', JSON.stringify(this.goodList))
                this.setLiveGoods(this.goodList);
                this.goodList.forEach((el) => { el.isChecked = 0 })
                if (this.temp_id) {
                    this.temp_id.forEach((el) => this.goodList.forEach((sub) => {
                        if (sub.id == el) sub.isChecked = 1
                    }))
                }
            })
        },
        saveCoupon(tag) {
            this.tag = tag
        },
        openWindow() {
            this.dialogVisible = true
            this.tempGoodsList = JSON.parse(JSON.stringify(this.goodList))
            this.pages.currentPageNum = 1
        },
        filterChecked() {
            this.goodList.map((el) => {
                if (el.isChecked === 1) {
                    this.temp_id.push(el.id)
                } else {
                    this.temp_id = this.temp_id.filter((sub) => sub != el.id)
                }
            })
            this.temp_id = [...new Set(this.temp_id)]
        },
        getAll() {
            this.goodList.forEach((el) => el.isChecked = 1)
            this.$forceUpdate()
            this.filterChecked()
        },
        changeItem(index, tag) {
            if (tag == 'choose') {
                this.goodList[index].isChecked = 1
                this.$forceUpdate()
            } else {
                this.goodList[index].isChecked = 0
                this.$forceUpdate()
            }
            this.filterChecked()
        },
        closeWinodw() {
            this.goodList = JSON.parse(JSON.stringify(this.tempGoodsList))
            this.tempGoodsList = []
            this.temp_id = []
            this.dialogVisible = false
        },
        saveGoods() {
            this.dialogVisible = false
            this.total = this.temp_id.length
            this.pages.currentPageNum = 1
            this.getGoodsList()
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.total == 0) {
                        this.$message.warning("请选择商品")
                        return
                    }
                    saveCouponData({ ...this.ruleForm, sale_goods_id: this.temp_id }).then((res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            localStorage.setItem('couponList', JSON.stringify(this.couponList))
                            this.tag = void 0
                            this.total = 0
                            this.temp_id = []
                            this.goodList.forEach((el) => el.isChecked = 0)
                            this.ruleForm = this.$options.data().ruleForm
                            this.getCouponListData()
                            this.getGoodsList()
                        }
                    })
                }
            });
        },
        resetForm(formName) {
            this.getGoodsList()
            this.temp_id = []
            this.tag = void 0
            this.total = 0
            this.goodList.forEach((el) => el.isChecked = 0)
            this.ruleForm = this.$options.data().ruleForm
        },
        editShop(row) {
            this.tag = 'edit';
            getCouponDetail({ id: row.id }).then((res) => {
                if (res.code === 200) {
                    this.ruleForm.circulation = res.data.circulation
                    this.ruleForm.coupon_type = res.data.coupon_type + ''
                    this.ruleForm.id = row.id
                    this.ruleForm.limit = res.data.limit
                    this.ruleForm.money = res.data.money
                    this.ruleForm.name = res.data.name
                    this.ruleForm.range = res.data.range + ''
                    this.ruleForm.threshold = res.data.threshold
                    this.goodList = JSON.parse(JSON.stringify(this.goodList.filter((el) => { return el.id == res.data.sale_goods_id })))
                    this.pages.total = 1
                    this.temp_id = [res.data.sale_goods_id]
                    this.goodList.forEach((el) => el.isChecked = 1)
                    this.total = this.goodList.length
                }
            })
        },
        delItem(id) {
            this.$confirm('是否确定删除该优惠券，删除后将无法恢复', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCouponItem({ id }).then((res) => {
                    if (res.code === 200) {
                        this.$message.success("删除成功!")
                        this.getCouponListData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        currentChange(val) {
            this.pages.currentPageNum = val;
            this.getGoodsList()
        }
    },
    mounted() {
        this.getCouponListData()
        this.getGoodsList()
    },
}
</script>

<style scoped lang="scss">
::v-deep .customTable td:first-child>.cell {
    padding-left: 25px;
}

::v-deep .customTable th:first-child>.cell {
    padding-left: 25px;
}

::v-deep .pages-center {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.goods-container {
    height: calc(100vh - 140px);
    display: flex;

    .script-box {
        width: 600px;
        height: 100%;
        margin-right: 20px;
    }

    .coupon-container {
        background-color: #ffffff;
        padding: 0px 0 0 30px;
        width: 100%;
        height: 100%;
        flex: 1;
        border-radius: 4px;
    }

    .goods-table {
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: hidden;
    }

    .nav-box {
        display: flex;
    }

    .goodsInfo {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: 58px;
            height: 58px;
            border-radius: 4px;
        }

        span {
            margin-left: 10px;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
}

.goodsInfo {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        width: 58px;
        height: 58px;
    }

    span {
        margin-left: 10px;
    }
}
</style>